#intro-root {
  height: 100%;
  width: 100%;
  height: 70rem;
}

#intro-outer_wave_container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
#intro-wave_container {
  position: relative;
  height: 70rem;
  background-color: var(--purple);
}
#intro-info_container {
  width: 100%;
  position: relative;
  z-index: 2;
}

.custom-shape-divider-bottom-1720816823 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1720816823 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 257px;
}

.custom-shape-divider-bottom-1720816823 .shape-fill {
  fill: white;
}

#intro-image_wrap {
  width: 100%;
  max-width: 30rem;
  margin: 0 4rem 0 4rem;
}
#intro-image {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 2px;
  box-shadow: 0px 16px 50px -4px rgba(0, 0, 0, 0.3);
  animation: introFadeInRight 0.35s ease-out 1;
  -webkit-animation: introFadeInRight 0.35s ease-out 1;
  -moz-animation: introFadeInRight 0.35s ease-out 1;
  -o-animation: introFadeInRight 0.35s ease-out 1;
}

#intro-text_container {
  width: 100%;
  max-width: 40rem;
  min-height: 32rem;
  height: auto;
}

#intro-text_container {
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 4rem 0 4rem;
}

#intro-buisiness_text_top_wrap {
  max-width: 50rem;
}

#intro-buisiness_header_text {
  font-size: 2rem;
  font-weight: 800;
  display: none;
}

#intro-buisiness_text_bottom_wrap {
  width: 100%;
}

#intro-buisiness_about_text {
  color: var(--dark);
  letter-spacing: 0.08rem;
  word-spacing: 0.1rem;
  font-size: 1.1rem;
  display: none;
}

#intro-buisiness_vision_text {
  font-weight: 600;
  letter-spacing: 0.08rem;
  word-spacing: 0.1rem;
  font-size: 1.1rem;
  display: none;
}

#intro-text_container {
  width: 100%;
  max-width: 40rem;
}

@keyframes introFadeInRight {
  from {
    opacity: 0;
    left: -20%;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@-webkit-keyframes introFadeInRight {
  from {
    opacity: 0;
    left: -20%;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@-moz-keyframes introFadeInRight {
  from {
    opacity: 0;
    left: -20%;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@-o-keyframes introFadeInRight {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.intro-fade {
  animation: introFade 0.5s ease-out 1;
  -webkit-animation: introFade 0.5s ease-out 1;
  -moz-animation: introFade 0.5s ease-out 1;
  -o-animation: introFade 0.5s ease-out 1;
}

@keyframes introFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes introFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes introFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes introFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
