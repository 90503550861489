@media only screen and (max-width: 1410px) {
  #about-root {
    flex-flow: row wrap;
  }
  #about-left_section {
    justify-content: flex-start;
  }
  #about-text_container {
    margin: 0 3rem 0 3rem;
  }
  #about-right_section {
    max-width: none;
  }
  #about-partial_bg {
    width: 36%;
  }
}

@media only screen and (max-width: 1128px) {
  #about-right_section {
    position: relative;
  }
  #about-partial_bg {
    width: 60%;
  }
  #about-img {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @keyframes aboutFadeInLeft {
    from {
      opacity: 0;
      left: 60%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }
  
  @-webkit-keyframes aboutFadeInLeft {
    from {
      opacity: 0;
      left: 60%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }
  
  @-moz-keyframes aboutFadeInLeft {
    from {
      opacity: 0;
      left: 60%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }
  
  @-o-keyframes aboutFadeInLeft {
    from {
      opacity: 0;
      left: 60%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }
}
