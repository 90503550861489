#alert {
  position: fixed;
  top: 1rem;
  left: 0;
  width: 90%;
  z-index: 1000;
  border: 1px solid;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: .3rem;
  animation: dropDown 1s ease-out 1;
  -webkit-animation: dropDown 0.5s ease-out 1;
  -moz-animation: dropDown 0.5s ease-out 1;
  -o-animation: dropDown 1s ease-out 1;
  box-shadow: 0px 16px 50px -4px rgba(0, 0, 0, 0.3);
  padding: .5rem 1rem;
}

@keyframes dropDown {
  from {
    top: -10%;
  }
  to {
    top: 1rem;
  }
}

@-webkit-keyframes dropDown {
  from {
    top: -10%;
  }
  to {
    top: 1rem;
  }
}

@-moz-keyframes dropDown {
  from {
    top: -10%;
  }
  to {
    top: 1rem;
  }
}

@-o-keyframes dropDown {
  from {
    top: -10%;
  }
  to {
    top: 1rem;
  }
}

#alert-content_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#alert-text {
  padding: 0;
  margin: 0;
  color: black;
  font-weight: 500;
}

#alert-close_icon {
  background: none;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 600;
  color: black;
  margin: 0;
  padding: 0;
}