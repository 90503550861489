#contact-form_form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0 0;
}

.contact-form_name_input_wraps {
  width: 100%;
  margin: 0.6rem 0;
  max-width: 16rem;
}

.contact-form_input_labels {
  color: black;
  font-weight: 600;
  font-size: 1rem;
}

.contact-form_sub_labels {
  color: #606060;
  font-weight: 400;
}

.contact-form_inputs {
  width: 100%;
  padding: 0.6rem;
  font-size: 1rem;
  outline: none;
  border-radius: 4px;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin: 0.3rem 0;
}

.contact-form_input_wraps {
  width: 100%;
  margin: 0.6rem 0;
}

.contact-form_textarea {
  width: 100%;
  margin: 0.3rem 0;
  outline: none;
  padding: 0.6rem;
  font-size: 1rem;
  border: 1px solid white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  max-width: auto;
}

#contact-form_submit_btn {
  background-color: var(--light);
  color: var(--dark);
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.3s ease;
}
