#nav {
  height: 4rem;
  width: 100%;
  background-color: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  left: 0;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  z-index: 50;
}

.navName {
  font-size: 1.5rem;
  font-weight: 900;
  color: black;
  margin-right: auto;
  margin-left: 0.5rem;
  text-decoration: none;
  padding: 0;
}

/* #desktopNavLogo {
  height: 4rem;
  margin-left: 0.5rem;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
} */

/* inner wrap surrounding all the content */

.navInnerContentWrap {
  display: none;
  align-items: center;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.navLinkWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.navLink {
  text-decoration: none;
  cursor: pointer;
  color: black;
  margin: 0 0.5rem;
  font-weight: 500;
  font-size: 1.3rem;
  border: 1px solid var(--baby-blue);
  background-color: var(--baby-blue);
  border-radius: 1rem;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
}

/* .navSpan {
  font-weight: 500;
  font-size: 1.3rem;
  text-decoration: none;
  letter-spacing: 0.05rem;
  display: inline-block;
  padding: 0.3rem 0;
  position: relative;
}

.navSpan:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #78bde4;
  -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -moz-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  -o-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navSpan:hover:after {
  width: 100%;
  left: 0;
} */

/* ! MOBILE NAV */
#mobileNav {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  z-index: 50;
}
/* navbar menu button */

#mobileNavBtn {
  width: 60px;
  height: 35px;
  position: relative;
  margin-left: auto;
  margin-right: 0.5rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#mobileNavBtn span {
  display: block;
  position: absolute;
  height: 5px;
  width: 74%;
  background: var(--baby-blue);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#mobileNavBtn span:nth-child(1) {
  top: 0px;
}

#mobileNavBtn span:nth-child(2) {
  top: 13px;
}

#mobileNavBtn span:nth-child(3) {
  top: 26px;
}

#mobileNavBtn.open span:nth-child(1) {
  top: 15px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#mobileNavBtn.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#mobileNavBtn.open span:nth-child(3) {
  top: 15px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.menu {
  height: 100vh;
  /* width: 250px; */
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: all 0.25s ease;
  transform: translateX(-100%);
  z-index: 51;
  box-shadow: 0 0 8px rgb(0, 0, 0);
  background-color: var(--purple);
}

.activeNav {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease;
  transform: translateX(0);
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

/* mobile nav links */

.mobileLink {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: black;
  margin: 2rem auto 0 auto;
  font-weight: 500;
  font-size: 1.3rem;
  border: 1px solid var(--baby-blue);
  background-color: var(--baby-blue);
  border-radius: 1rem;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
}

#mobileJackAttack {
  display: block;
  width: 100%;
  max-width: 15rem;
  margin-top: 1rem;
}
