#connect-form_form {
  width: 100%;
  /* display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center; */
  padding: 1.5rem;
}

.connect_form-input_block {
  width: 100%;
  display: block;
  margin: .6rem 0;
}

.connect_form-label {
  color: black;
  font-weight: 600;
  font-size: 1rem;
}

.connect_form-input {
  width: 100%;
  padding: 0.6rem;
  font-size: 1rem;
  outline: none;
  border-radius: 4px;
  border: 1px solid white;
  box-shadow: rgba(143, 143, 143, 0.15) 0px 2px 8px;
  margin: 0.3rem 0;
}
.select_dropdown {
  background-color: red;
}