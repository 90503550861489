#connect-root {
  width: 100%;
  height: 100%;
}

#connect-card {
  height: 100%;
  min-height: 50rem;
  margin: 4rem 1rem 4rem 1rem;
  width: 100%;
  max-width: 50rem;
  box-shadow: 0px 18px 37px 8px rgba(0,0,0,0.1);;
}