#footer {
  width: 100%;
  background-color: var(--green);
  padding: 1rem 0;
}

#footer-text {
  padding: 0;
  margin: 0;
  color: white;
  font-size: 1rem;
  font-weight: 300;
}