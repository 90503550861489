@font-face {
  font-family: 'Nanum Gothic';
  src: url('./assets/fonts/NanumGothic-Regular.ttf');
}

:root {
  --dark: #2b2b2b;
  --light: #f5f5f5;
  --red: #d83d2e;
  --baby-blue: #78bde4;
  --green: #2f4230;
  --purple: #6469ab;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: 1px;
  background-color: #ffffff;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    letter-spacing: 1px;
}