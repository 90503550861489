@media only screen and (max-width: 1398px) {
  #intro-image_wrap {
    margin: 0 2rem 0 2rem;
  }
  #intro-text_container {
    max-width: 35rem;
    margin: 0 2rem 0 2rem;
  }
}

@media only screen and (max-width: 1182px) {
  #intro-image_wrap {
    max-width: 26rem;
  }
  #intro-text_container {
    max-width: 25rem;
  }
}

@media only screen and (max-width: 959px) {
  #intro-image_wrap {
    max-width: 26rem;
  }
  #intro-text_container {
    max-width: 25rem;
    margin: 0 2rem 0 2rem;
  }

  #intro-root {
    height: 91rem;
  }
  #intro-wave_container {
    height: 94rem;
  }
}
