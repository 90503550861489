#social-title {
  margin: 0;
  padding: 0;
  color: var(--light);
  font-size: 1.8rem;
  font-weight: 800;
}

.social-link_wrap {
  display: flex;
  align-items: center;
  color: green;
  font-weight: 600;
  font-size: 1rem;
  margin: .2rem .5rem;
  text-decoration: none;
}
/* .social-link_wrap:focus {
  color: var(--light);
} */

.social-icon {
  margin: 0 .5rem 0 0;
  font-size: 1.4rem;
  color: black;
}