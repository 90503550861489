#connect_header-root {
}

#connect_header-img_overlay {
  border-radius: 50%;
  width: 15rem;
  height: 15rem;
  overflow: hidden;
  position: relative;
  margin: 1rem 0;
}

#connect_header-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16rem;
}
