/* #about-left_wrap {
  width: 100%;
  max-width: 40rem;
  margin: 1rem;
}

#about-title {
  font-size: 3rem;
  font-weight: 800;
}

.about-paragraph {
  letter-spacing: 0.08rem;
  word-spacing: 0.1rem;
  font-size: 1.1rem;
  margin: 2rem 0 1.5rem 0;
  color: var(--dark);
}

#about-right_wrap {
  width: 100%;
  max-width: 40rem;
  margin: 1rem;
}

#about-img {
  width: 100%;
} */

#about-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#about-left_section {
  width: 100%;
  max-width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#about-text_container {
  width: 100%;
  max-width: 40rem;
}

#about-right_section {
  width: 100%;
  max-width: 65rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#about-partial_bg {
  background-color: var(--green);
  width: 75%;
  height: 32rem;
  display: flex;
  align-items: center;
  border-radius: 2px
}

#about-img {
  width: 100%;
  max-width: 18rem;
  margin: 0 0 0 -6rem;
  box-shadow: 0px 16px 50px -4px rgba(0,0,0,0.3);
  border-radius: 2px;
  display: none;
}

.about-animate-image {
  display: block;
  animation: aboutFadeInLeft 0.35s ease-out 1;
  -webkit-animation: aboutFadeInLeft 0.35s ease-out 1;
  -moz-animation: aboutFadeInLeft 0.35s ease-out 1;
  -o-animation: aboutFadeInLeft 0.35s ease-out 1;
  will-change: opacity, transform;
}

@keyframes aboutFadeInLeft {
  from {
    opacity: 0;
    transform: translateX(30%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes aboutFadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translateX(30%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes aboutFadeInLeft {
  from {
    opacity: 0;
    -moz-transform: translateX(30%);
  }
  to {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes aboutFadeInLeft {
  from {
    opacity: 0;
    -o-transform: translateX(30%);
  }
  to {
    opacity: 1;
    -o-transform: translateX(0);
  }
}