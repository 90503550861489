#progressBarWrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: transparent;
  height: 4px;
  display: block;
}

#progressBar {
  background-color: var(--green);
  width: 0%;
  display: block;
  height: inherit;
}