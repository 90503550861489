.layoutButton {
  background-color: var(--light);
  color: var(--dark);
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 8rem;
}
.layoutButton:focus {
  outline: none;
}