/* #contact-container {
  background-color: var(--dark);
  width: 100%;
  padding: 0.5rem;
}

#contact-form_wrap {
  width: 100%;
  max-width: 40rem;
  margin: 1rem;
}

#contact-title {
  color: white;
  font-size: 1.8rem;
  font-weight: 800;
  text-align: left;
  margin: 0;
  padding: 0;
}

#contact-social_wrap {
  width: 100%;
  max-width: 40rem;
  margin: 1rem;
} */

#contact-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#contact-left_section {
  width: 100%;
  max-width: 65rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#contact-partial_bg {
  background-color: var(--purple);
  width: 75%;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 2px;
}

#contact-text_wrap {
  background-color: var(--baby-blue);
  width: 24rem;
  padding: 1rem;
  border-radius: 2px;
  margin: 0 -6rem 0 0;
  box-shadow: 0px 16px 50px -4px rgba(0, 0, 0, 0.3);
  display: none;
}

#contact-title {
  margin: 0.5rem 0 1rem 0;
}

#contact-right_section {
  width: 100%;
  max-width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact-form {
  padding: 1rem;
  width: 100%;
  max-width: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-animate-image {
  display: block;
  animation: contactFadeInRight 0.35s  ease-out 1;
  -webkit-animation: contactFadeInRight 0.35s  ease-out 1;
  -moz-animation: contactFadeInRight 0.35s  ease-out 1;
  -o-animation: contactFadeInRight 0.35s  ease-out 1;
  will-change: opacity, transform;
}

@keyframes contactFadeInRight {
  from {
    opacity: 0;
    transform: translateX(-30%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes contactFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translateX(-30%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes contactFadeInRight {
  from {
    opacity: 0;
    -moz-transform: translateX(-30%);
  }
  to {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes contactFadeInRight {
  from {
    opacity: 0;
    -o-transform: translateX(-30%);
  }
  to {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

@media only screen and (max-width: 1238px) {
  #contact-root {
    flex-flow: row wrap;
  }
  #contact-left_section {
    max-width: none;
  }
  #contact-partial_bg {
    width: 36%;
  }
  #contact-right_section {
    max-width: none;
    justify-content: flex-end;
  }
  #contact-form {
    margin: 2rem;
  }
}

@media only screen and (max-width: 1128px) {
  #contact-partial_bg {
    width: 36%;
  }
  #contact-left_section {
    position: relative;
  }
  #contact-partial_bg {
    width: 60%;
  }
  #contact-text_wrap {
    width: 90%;
    max-width: 28rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  #contact-right_section {
    width: 100%;
    max-width: none;
    justify-content: center;
  }
  #contact-form {
    padding: 0;
    width: 100%;
  }

  @keyframes contactFadeInRight {
    from {
      opacity: 0;
      left: 40%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }
  @-webkit-keyframes contactFadeInRight {
    from {
      opacity: 0;
      left: 40%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }

  @-moz-keyframes contactFadeInRight {
    from {
      opacity: 0;
      left: 40%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }

  @-o-keyframes contactFadeInRight {
    from {
      opacity: 0;
      left: 40%;
    }
    to {
      opacity: 1;
      left: 50%;
    }
  }
}
